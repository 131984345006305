<template>
  <div class="home">
    <div class="in-container">
      <carousel />
    </div>
    <div class="d-flex flex-column align-items-center items container">
      <div class="title">
        YAFO Capital
      </div>
      <div class="divider">
        <img
          src="@/assets/images/divider.png"
          alt=""
        >
      </div>
      <div class="content text-center">
        <p>
          Founded in 2013, YAFO Capital is a Shanghai based boutique investment & advisory firm focusing on bringing
          global technologies to Asia. We started from investing in Israeli Medtech to China back to 2013, YAFO has
          established four joint ventures with our global partners in Japan, Italy, Germany, and Switzerland.
        </p>
        <p>
          YAFO has four pillar business lines including: investment banking, fund investment, incubation, and biotech
          forum .
        </p>
        <p>
          YAFO, the Hebrew word of Jaffa (now part of Tel Aviv), means beautiful. For 3500 years, the old Jaffa port has
          connected the ancient city with the rest of the world.
        </p>
        <p>
          YAFO’s mission is to make our life more beautiful by introducing global technologies.
        </p>
      </div>
    </div>
    <div class="section background-2 items">
      <div class="container text-center">
        YAFO Life Sciences – Bridging the Gap Between Global Biopharma & Medtech and Asia.
      </div>
    </div>
    <div class="d-flex flex-column align-items-center items container">
      <div class="title">
        YAFO Life Sciences
      </div>
      <div class="divider">
        <img
          src="@/assets/images/divider.png"
          alt=""
        >
      </div>
      <div class="text-center content">
        YAFO Life Sciences is a leading advisory boutique focused exclusively on the cross-border Life Sciences
        transactions. In 8 years, YAFO has built a strong proven track record and extensive network in China pharma
        industry. YAFO ranked No. 1 advisor for China cross border licensing transactions by China Healthcare Business
        Development Association in 2020 & 2021.
      </div>
      <div class="item-link">
        <router-link :to="{name:'Science'}">
          View YAFO Life Sciences
        </router-link>
      </div>
    </div>
    <div class="section background-4 items">
      <div class="text-center container">
        Incubate world leading bio-innovation for Asian patients！
      </div>
    </div>
    <div class="d-flex flex-column align-items-center items container">
      <div class="title">
        Incubation
      </div>
      <div class="divider">
        <img
          src="@/assets/images/divider.png"
          alt=""
        >
      </div>
      <div class="text-center content">
        With significant life sciences industry expertise and experience in drug development, manufacture, and sales, we
        are dedicated to identify, in-license, develop, and commercialize the high potential innovative drug assets and
        new technologies from worldwide. By leveraging our clinical, BD, and commercialization skills in local markets,
        we are able to accelerate our partnering assets’ development and promote best practices in China, Japan, India,
        and other Asia countries.
      </div>
      <div class="item-link">
        <router-link :to="{name:'Incubation'}">
          View Incubation
        </router-link>
      </div>
    </div>
    <div class="section background-3 items">
      <div class="text-center container">
        Navigating Entry into China and Japan Markets and Access to Additional Resources and Funding.
      </div>
    </div>
    <div class="d-flex flex-column align-items-center items container">
      <div class="title">
        YAFO Fund
      </div>
      <div class="divider">
        <img
          src="@/assets/images/divider.png"
          alt=""
        >
      </div>
      <div class="text-center content">
        <p>
          YAFO is a licensed fund manager under the Asset Management Association of China since 2015. YAFO Healthcare
          Fund actively invests in innovative companies including biotechnology, medical devices, diagnostics, genomics
          and others.
        </p>
        <p>
          YAFO Buy-out fund, along with pharma companies, invest in global or domestic late stage assets through
          license-in or M&A
        </p>
      </div>
      <div class="item-link">
        <router-link :to="{name:'FundManagement'}">
          View YAFO Fund
        </router-link>
      </div>
    </div>
    <div class="section background-1" />
    <div class="d-flex flex-column align-items-center items container">
      <div class="title">
        Our Team Members
      </div>
      <div class="divider">
        <img
          src="@/assets/images/divider.png"
          alt=""
        >
      </div>
      <div class="text-center content">
        Our expertise allows YAFO Capital to deliver added value throughout all aspects of a transaction – opportunity
        identification, strategy development, deal planning, execution, negotiations, structuring, and closing.
      </div>
      <b-row class="team align-items-start">
        <b-col
          v-for="item in members"
          :key="item.id"
          cols="6"
          sm="auto"
          class="member"
        >
          <div class="avatar-container">
            <img
              class="avatar"
              :src="item.avatar.url"
              alt=""
            >
          </div>
          <div class="name">
            {{ item.name }}
          </div>
          <div class="position">
            {{ item.position }}
          </div>
          <div class="desc">
            {{ item.background }}
          </div>
        </b-col>
      </b-row>
      <div class="item-link">
        <router-link :to="{name:'Team'}">
          Go Team
        </router-link>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center items container">
      <div class="title">
        Events & News
      </div>
      <div class="divider">
        <img
          src="@/assets/images/divider.png"
          alt=""
        >
      </div>
      <b-row class="w-100 event">
        <b-col
          v-for="item in news"
          :key="item.id"
          class="mb-2 event-item"
          cols="12"
          sm="4"
        >
          <div class="thumbnail">
            <div class="cover">
              <img
                :src="item.cover.url"
                alt=""
              >
            </div>
            <div class="title text-center">
              {{ item.title }}
            </div>
            <div class="text-center time">
              {{ item.time }}
            </div>
            <div class="intro">
              {{ item.introduction }}
            </div>
            <div class="text-center">
              <router-link
                class="link"
                :to="{name:'Event Detail',params:{id:item.id}}"
              >
                View More
                <b-icon icon="chevron-right" />
              </router-link>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="item-link">
        <router-link :to="{name:'Events'}">
          View More
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Watch, Vue } from 'vue-property-decorator'
import Carousel from '@/components/Carousel'
import { getTeam } from '@/api/team'
import { getList } from '@/api/event'

export default @Component({
  components: {
    Carousel
  }
})
class About extends Vue {
  members = []
  news = []
  slide = 0

  mounted () {
    this.getData()
  }

  @Watch('$i18n.locale')
  async getData () {
    this.members = await getTeam({
      group: 'china',
      _start: 0,
      _limit: 5
    })
    this.news = [...await getList({
      type: 'upcoming',
      _start: 0,
      _limit: 1
    }), ...await getList({
      type: 'news',
      _start: 0,
      _limit: 2
    })]
  }
}
</script>
<style lang="scss" scoped>

.subtitle {
  font-size: 20px;

  &.effect {
    text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
  }
}

.banner-image {
  height: 450px;
}

.section {
  font-size: 2rem;
  height: 450px;
  margin-left: -15px;
  margin-right: -15px;
  color: #ffffff;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.background-1 {
    background-image: url("../assets/images/home/banner_2.png");
  }

  &.background-2 {
    background-image: url("../assets/images/home/background_3.jpg");
  }

  &.background-3 {
    background-image: url("../assets/images/home/background_2.jpg");
    background-position: top;
  }

  &.background-4 {
    background-image: url("../assets/images/biocubate/background.jpg");
  }

}

.team {
  margin-top: 60px;
  flex-wrap: wrap;

  .member {
    color: #7f7f7f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;

    .avatar-container {
      padding: 6px;
      border: 1px solid #e2e1e1;
      border-radius: 50% !important;

      .avatar {
        border-radius: 50%;
      }
    }

    .name {
      font-size: 18px;
      margin: 10px 0;
    }

    .position {
      font-size: 14px;
      text-align: center;
      margin-bottom: 10px;
    }

    .desc {
      font-size: 14px;
      text-align: center;
    }
  }
}

.event {
  .thumbnail {
    padding: 0 8px 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;

    .cover {
      margin: 0 -8px;
      border-bottom: 1px solid #ddd;

      img {
        width: 100%;
        height: 190px;
        object-fit: cover;
      }
    }

    .title {
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 10px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .time {
      font-size: 14px;
      color: #7f7f7f;
    }

    .intro {
      margin-top: 12px;
      font-size: 14px;
      color: #7f7f7f;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 110px;
      display: -webkit-box;
      word-break: break-all;
      text-align: justify;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }

    .link {
      color: #337ab7;
      text-decoration: none;
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 768.1px) {
  .section {
    font-size: 1.25rem;
    height: 450px;
  }

  .team {
    flex-wrap: wrap;

    .member {
      margin-top: 1.5rem;
      width: 40%;

      &:first-child {
        margin-left: 1rem;
      }

      &:last-child {
        margin-right: 1rem;
      }
    }
  }
}

@media (max-width: 479px) {
  .section {
    font-size: 1rem;
    height: 250px;
  }
  .team {
    flex-wrap: wrap;

    .member {
      margin-top: 40px;
      width: 48%;
      margin-left: 0 !important;
      margin-right: 0 !important;

      &:first-child {
        margin-right: 0;
      }

      &:last-child {
        margin-left: 0;
      }

      .name {
        font-size: 1rem;
        text-align: center;
      }

      .position {
        font-size: 14px;
        margin-bottom: 8px;
      }

      .desc {
        font-size: 12px;
      }
    }
  }
}

.event {
  align-items: stretch;

  .event-item {
    .thumbnail {
      display: flex;
      flex-direction: column;
      height: 100%;

      .intro {
        flex: 1;
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
